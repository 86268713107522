/** @jsx jsx */
import { Box, Grid, Themed, jsx, ThemeUICSSObject } from 'theme-ui';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Link } from '@sprinklr/shared-lib';

const TITLE_SX: ThemeUICSSObject = {
  color: '#A0A0A8',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: [1, 2],
  letterSpacing: '1.2px',
  textAlign: ['center', null, null, 'left'],
  fontFamily: 'body',
  lineHeight: 1.4,
};

const BASE_CONTAINER_SX: ThemeUICSSObject = { pt: ['40px', '50px'] };

const CONTAINER_SX: ThemeUICSSObject = {
  gridTemplateColumns: ['1fr', '1fr 1fr 1fr', null, '1fr'],
  gridRowGap: '10px',
  lineHeight: '20px',
};

const LINK_SX: ThemeUICSSObject = {
  fontWeight: 'semiBold',
  padding: [0],
  fontSize: ['14px', '14px', '14px', '16px'],
  ':hover': {
    borderBottomColor: 'transparent',
    textDecoration: 'underline',
  },
};

type Article = {
  slug: string;
  title: string;
};

const RelatedTopics = ({ relatedTopics }: { relatedTopics: Article[] }) => {
  const { t } = useTranslation();

  return (
    <Box sx={BASE_CONTAINER_SX}>
      <Themed.h6 as="p" sx={TITLE_SX}>
        {t('Related Topics')}
      </Themed.h6>
      <Grid sx={CONTAINER_SX}>
        {relatedTopics.map(topic => {
          return (
            <Link key={topic.slug} href={topic.slug} sx={LINK_SX}>
              {topic.title}
            </Link>
          );
        })}
      </Grid>
    </Box>
  );
};

export default RelatedTopics;
