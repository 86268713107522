import CareIcon from '@sprinklr/shared-lib/assets/svgs/icon-care.svg';
import MarketingIcon from '@sprinklr/shared-lib/assets/svgs/icon-marketing.svg';
import ResearchIcon from '@sprinklr/shared-lib/assets/svgs/icon-research.svg';
import SalesIcon from '@sprinklr/shared-lib/assets/svgs/icon-sales.svg';
import BurstIcon from '@sprinklr/shared-lib/assets/svgs/sprinklr.svg';

export const getFeaturedProductsData = () => ({
  'Sprinklr Service': {
    Icon: CareIcon,
    productLabel: 'Sprinklr Service',
    productSlug: '/products/customer-service/',
    productList: [],
  },
  'Sprinklr Insights': {
    Icon: ResearchIcon,
    productLabel: 'Sprinklr Insights',
    productSlug: '/products/consumer-intelligence/',
    productList: [],
  },
  'Sprinklr Marketing': {
    Icon: MarketingIcon,
    productLabel: 'Sprinklr Marketing',
    productSlug: '/products/marketing-and-advertising/',
    productList: [],
  },
  'Sprinklr Social': {
    Icon: SalesIcon,
    productLabel: 'Sprinklr Social',
    productSlug: '/products/social-media-management/',
    productList: [],
  },
  Platform: {
    Icon: BurstIcon,
    productLabel: 'Platform',
    productSlug: '/products/platform/',
    productList: [],
  },
});
