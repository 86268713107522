/** @jsx jsx */
import React from 'react';
import { Grid, Box, jsx } from 'theme-ui';
import { PostPageContentColumnsProps } from './types';
import PostPageStickyColumn from './PostPageStickyColumn';
import PostPageCategoryCards from './PostPageCategoryCards';
import PostPageSocialShare from './PostPageSocialShare';
import PostPageSectionDivider from './PostPageSectionDivider';
import PostPageBody from './PostPageBody';

const PostPageContentColumns: React.FC<PostPageContentColumnsProps> = props => {
  const {
    postTitle,
    postBody,
    featuredProductCategories,
    featuredProductPageLinks,
    primaryCategory,
    secondaryCategory,
    nodeLocale,
    demoForm,
    subscribeForm,
    socialSectionTitle,
    productSectionTitle,
    relatedTopics,
    pageType,
    styleVariant,
  } = props;

  return (
    <Grid
      sx={{
        gridTemplateColumns: ['1fr', '1fr', '1fr', '3fr 1fr'],
        gridGap: [5, 5, null, 6],
        alignItems: 'column-reverse',
        marginBottom: ['80px', null, null, '100px'],
      }}
    >
      <Box
        sx={{
          width: '100%',
          img: {
            maxHeight: ['100%', '600px'],
            maxWidth: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
          },
        }}
        className={'scn-post-page-first-column'}
      >
        <Box
          sx={{
            paddingBottom: ['40px', '50px', '50px'],
            display: ['block', null, null, 'none'],
          }}
        >
          <PostPageSocialShare
            name={postTitle}
            socialSectionTitle={socialSectionTitle}
            postPageSocialShareSx={{
              marginBottom: ['40px', '50px', '50px'],
            }}
          />
          <PostPageSectionDivider />
        </Box>
        <PostPageBody
          styleVariant={styleVariant}
          postBody={postBody}
          pageType={pageType}
        />
        {primaryCategory && (
          <PostPageCategoryCards
            primaryCategory={primaryCategory}
            secondaryCategory={secondaryCategory}
            nodeLocale={nodeLocale}
            pageType={pageType}
          />
        )}
      </Box>
      <Box
        sx={{ minWidth: [null, null, null, '200px'] }}
        className={'scn-post-page-sticky-column'}
      >
        <PostPageStickyColumn
          postTitle={postTitle}
          featuredProductCategories={featuredProductCategories}
          featuredProductPageLinks={featuredProductPageLinks}
          demoForm={demoForm}
          subscribeForm={subscribeForm}
          productSectionTitle={productSectionTitle}
          relatedTopics={relatedTopics}
          nodeLocale={nodeLocale}
          socialSectionTitle={socialSectionTitle}
          pageType={pageType}
        />
      </Box>
    </Grid>
  );
};

export default PostPageContentColumns;
