import React, { useMemo } from 'react';
import {
  Box,
  ThemeProvider,
  Theme,
  ThemeUIStyleObject,
  ThemeStyles,
} from 'theme-ui';
import RichText from '../richText';
import TableOfContents from '../tableOfContents';
import { getTableOfContentsRichText } from '../tableOfContents/utils';
import { PostPageBodyProps } from './types';

const RICH_TEXT_WRAPPER_STYLES = {
  marginTop: ['32px', '52px'],
};

function getContainerStyles(styleVariant: string): ThemeUIStyleObject {
  return {
    ':nth-child(1) > :nth-child(1):is(h2)': {
      mt: styleVariant === 'REDUCED_HEADLINE' && 0,
    },
  };
}

const REDUCED_HEADLINE_CONTENT_BODY_STYLES = {
  h2: { fontSize: [6, 9], mt: ['30px', '50px'] },
  h3: { fontSize: [4, 7], mt: ['20px', '24px'], fontWeight: 'semiBold' },
  h4: { fontSize: [3, 5], mt: ['20px', '30px'] },
  h5: { fontSize: [0, 2], mt: ['20px', '30px'] },
  h6: { fontSize: [0, 1], mt: ['20px', '30px'] },
  ul: { mb: '40px' },
  ol: { mb: '40px' },
};

const BASE_THEME_PROVIDER_STYLES: ThemeStyles = {
  blockquote: {
    background: 'transparent',
    textAlign: 'left',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: [0, 0],
    paddingBottom: [0, 0],
    borderRadius: '0px',
    borderLeft: '3px solid #00BAE9',
    p: {
      textAlign: 'left',
      fontSize: ['18px', '24px', null, '30px'],
    },
  },
  hr: {
    my: [5],
    width: '100%',
  },
  li: {
    flexDirection: 'column',
  },
  p: {
    mb: ['24px', '30px'],
  },
};

const PostPageBody = ({
  styleVariant,
  postBody,
  pageType,
}: PostPageBodyProps) => {
  const themeProviderStyles: Theme = useMemo(
    () => ({
      styles: {
        ...BASE_THEME_PROVIDER_STYLES,
        ...(styleVariant === 'REDUCED_HEADLINE'
          ? REDUCED_HEADLINE_CONTENT_BODY_STYLES
          : {}),
      },
    }),
    [styleVariant],
  );

  const [bodyRichTextData, tableOfContentsRichTextData] = useMemo(
    () => getTableOfContentsRichText(postBody, pageType === 'BLOG'),
    [postBody, pageType],
  );

  const containerStyles = useMemo(
    () => getContainerStyles(styleVariant),
    [styleVariant],
  );

  return (
    <ThemeProvider theme={themeProviderStyles}>
      <Box sx={containerStyles}>
        {tableOfContentsRichTextData ? (
          <TableOfContents richTextJSON={tableOfContentsRichTextData} />
        ) : null}
        <Box sx={RICH_TEXT_WRAPPER_STYLES}>
          <RichText richTextObject={bodyRichTextData} />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default PostPageBody;
