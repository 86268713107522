export const getSupportedLanguages = () => ['', `fr`, `es`, `de`, `it`, `ar`];
export const getBlogSupportedLanguages = () => ['', `fr`];
export const supportedLocalesForHeaderV3 = [
  'en-US',
  'fr-FR',
  'es-001',
  'de-DE',
  'ja-JP',
  'ar',
];
export const getNodeLocales = () => [
  'en-US',
  'fr-FR',
  'ja-JP',
  'es-001',
  'de-DE',
  'pt-BR',
  'en-GB',
  'ko',
  'en-SG',
  'en-AU',
  'it',
  'ar',
];
export const blogContextSupportedTranslations = () => [
  'English (en-US)',
  'French (fr-FR)',
  'Portuguese (pt-BR)',
];

export const getLanguageContextVariableByLocaleLookup = () => ({
  'en-US': 'en',
  'fr-FR': 'fr',
  'ja-JP': 'jp',
  'es-001': 'es',
  'de-DE': 'de',
  'pt-BR': 'pt-br',
  'en-GB': 'gb',
  ko: 'kr',
  'en-SG': 'sg',
  'en-AU': 'au',
  it: 'it',
  ar: 'ar',
});

export const getNodeLocaleLookup = () => ({
  en: 'en-US',
  fr: 'fr-FR',
  jp: 'ja-JP',
  es: 'es-001',
  de: 'de-DE',
  'pt-br': 'pt-BR',
  gb: 'en-GB',
  kr: 'ko',
  sg: 'en-SG',
  au: 'en-AU',
  it: 'it',
  ar: 'ar',
});

export const getNodeLocalesPaginationObject = () => ({
  'en-US': 0,
  'fr-FR': 0,
  'ja-JP': 0,
  'es-001': 0,
  'de-DE': 0,
  'pt-BR': 0,
  'en-GB': 0,
  ko: 0,
  'en-SG': 0,
  'en-AU': 0,
  it: 0,
  ar: 'ar',
});

const translationVariableLookup = {
  'en-US': 'English (en-US)',
  'fr-FR': 'French (fr-FR)',
  'ja-JP': 'Japanese (ja-JP)',
  'es-001': 'Spanish (es-001)',
  'de-DE': 'German (de-DE)',
  'pt-BR': 'Portuguese (pt-BR)',
  'en-GB': 'English (en-GB)',
  ko: 'Korean (ko)',
  'en-SG': 'English (en-SG)',
  'en-AU': 'English (en-AU)',
  it: 'Italian (it)',
  ar: 'Arabic (ar)',
};

export const getTranslationContextVariable = locale => {
  return translationVariableLookup[locale];
};

export const pageShouldRenderInLocale = data => {
  const { nodeLocale, translations } = data;
  return translations?.languagesToRenderIn?.some(
    language => language === 'All' || language.includes(nodeLocale),
  );
};

export const filterPostPageDataByTranslations = data => {
  return data.reduce((filteredData, postPageData) => {
    pageShouldRenderInLocale(postPageData) && filteredData.push(postPageData);
    return filteredData;
  }, []);
};

export const translationsByPageNumber = (
  filteredPaginationData: Record<string, number>,
) => {
  if (!filteredPaginationData) return;
  const counts = Array.from(new Set(Object.values(filteredPaginationData)));

  const languagesByPageCount = [];
  counts.map(count => {
    const matchingCounts = Object.entries(filteredPaginationData).filter(
      ([lang, counts]) => counts === count,
    );

    languagesByPageCount[+count] = matchingCounts.map(([lang, count]) =>
      getTranslationContextVariable(lang),
    );
  });

  return languagesByPageCount;
};

export const paginationDataSupportedTranslations = (
  paginationData: Record<string, number>,
) => {
  if (!paginationData) return;
  return Object.fromEntries(
    Object.entries(paginationData).filter(([key]) =>
      blogContextSupportedTranslations().includes(
        getTranslationContextVariable(key),
      ),
    ),
  );
};

export const translationsForPaginatedPages = (
  paginatedPageNumber: number,
  translationsByPageNumber = [],
  contextTranslations = [],
) => {
  if (translationsByPageNumber?.length > 0) {
    return translationsByPageNumber
      .slice(paginatedPageNumber, translationsByPageNumber.length)
      .flat();
  }
  return contextTranslations?.length > 0
    ? contextTranslations
    : ['English (en-US)'];
};
