export type BlogSchema = {
    "@context": string;
    "@type": string;
    mainEntityOfPage: {
        "@type": string;
        "@id": string;
    };
    headline: string;
    description: string;
    author: {
        "@type": string;
        name: string;
        url?: string;
    };
    publisher: {
        "@type": string;
        name: string;
        logo: {
            "@type": string;
            url: string;
        };
    };
    datePublished: string;
};

export const BLOG_PUBLISHER = {
    "@type": "Organization",
    name: "Sprinklr",
    logo: {
        "@type": "ImageObject",
        url: "https://www.sprinklr.com/icons/icon-512x512.png"
    }
};


export const getBlogSchema = ({
    url,
    headline,
    description,
    authorName,
    authorUrl,
    datePublished,
}: {
    url: string;
    headline: string;
    description: string;
    authorName: string;
    authorUrl?: string;
    datePublished: string;
}): BlogSchema => {
    const author = { "@type": "Person", name: authorName, url: authorUrl };
    const mainEntityOfPage = { "@type": "WebPage", "@id": url };

    return {
        "@context": "https://schema.org",
        "@type": "Article",
        mainEntityOfPage,
        headline,
        description,
        author,
        publisher: BLOG_PUBLISHER,
        datePublished,
    };
};
