/** @jsx jsx */
import React, { Fragment, ReactElement, useMemo } from 'react';
import { jsx, Box, Flex, Themed } from 'theme-ui';
import { useResponsiveValue } from '@theme-ui/match-media';
import { useI18next } from 'gatsby-plugin-react-i18next';

import PageCardImage from '@sprinklr/website/src/templates/pageCardDisplayTemplate/components/PageCardImage';
import { Card, Link } from '@sprinklr/shared-lib';

import { removeNodeLocaleFromSlug } from '@sprinklr/shared-lib/utils/removeNodeLocaleFromSlug';
import { getPostPageSlug } from '@sprinklr/shared-lib/utils/getPostPageSlugs';
import setTextTruncate from '@sprinklr/shared-lib/utils/setTextTruncate';
import { getLocalizedDate } from '@sprinklr/shared-lib/utils/getLocalizedDate';
import { getBlogCategoriesDataLookup } from '../../utils/blogCategoriesLookup';

import {
  sharedStyles,
  styleVariants,
} from '@sprinklr/website/src/templates/pageCardDisplayTemplate/styles';

import {
  CARD_CATEGORY_LINK_STYLES,
  CARD_CATEGORY_STYLES,
  CARD_INFO_STYLES,
  CARD_LINK_STYLES,
  IMAGE_LINK_STYLES,
} from './styles';

const MINUTES_TO_READ = 'min read';
const SLUG_PREFIX = 'blog';

type BlogAuthor = {
  person: {
    authorName?: string;
    slug?: string;
  };
};

type Props = {
  slug: string;
  cardTitle: string;
  cardDescription?: string;
  cardImage?: any;
  publishDate?: any;
  category?: string;
  blogAuthors?: BlogAuthor[];
  node_locale: string;
  minutesToRead?: number;
  variant: string;
  pageCardSx?: any;
};

const BlogPostCard = ({
  slug,
  cardTitle,
  cardDescription,
  cardImage,
  publishDate,
  category,
  blogAuthors,
  node_locale,
  minutesToRead,
  variant,
  pageCardSx,
}: Props): ReactElement => {
  const { t } = useI18next();

  const localizedDate = useMemo(
    () => getLocalizedDate(publishDate, node_locale),
    [publishDate, node_locale],
  );

  const href = useMemo(
    () =>
      removeNodeLocaleFromSlug(getPostPageSlug(slug, SLUG_PREFIX, node_locale)),
    [slug, node_locale],
  );

  const {
    cardSx,
    imageSx,
    bodySx,
    titleContainerSx,
    titleSx,
    descriptionContainerSx,
  } = useMemo(() => styleVariants[variant], [variant]);

  const { linkStyles, cardStyles, descriptionStyles } = sharedStyles;

  const containerStyles = useMemo(
    () => ({
      ...cardStyles,
      ...cardSx,
      ...pageCardSx,
    }),
    [cardStyles, cardSx, pageCardSx],
  );

  const flexStyles = useMemo(
    () => ({
      height: '100%',
      flexDirection: 'column',
      ...bodySx,
    }),
    [bodySx],
  );

  const titleContainerStyles = useMemo(
    () => ({ ...setTextTruncate('2'), ...titleContainerSx }),
    [titleContainerSx],
  );

  const titleStyles = useMemo(
    () => ({
      lineHeight: ['25px', '28px', '28px', '28px'],
      ...titleSx,
    }),
    [titleSx],
  );

  const descriptionContainerStyles = useMemo(
    () => ({
      ...setTextTruncate('3'),
      ...descriptionContainerSx,
    }),
    [descriptionContainerSx],
  );

  const shouldDisplayDescription = useMemo(
    () =>
      !!(variant === 'verticalShadow' && cardDescription) ||
      !!(variant === 'horizontalShadow' && cardDescription),
    [variant, cardDescription],
  );

  return (
    <Box sx={linkStyles}>
      <Card cardSx={containerStyles}>
        <Link href={href} sx={IMAGE_LINK_STYLES}>
          <PageCardImage
            cardImage={cardImage}
            cardTitle={cardTitle}
            imageSx={imageSx}
          />
        </Link>

        <Flex sx={flexStyles}>
          {category && <PageCategoryLink category={t(category)} />}
          <Box sx={titleContainerStyles}>
            <Themed.h6 sx={titleStyles} as="p">
              <Link href={href} sx={CARD_LINK_STYLES}>
                {cardTitle}
              </Link>
            </Themed.h6>
          </Box>
          {shouldDisplayDescription && (
            <Box sx={descriptionContainerStyles}>
              <Themed.p sx={descriptionStyles}>{cardDescription}</Themed.p>
            </Box>
          )}
          <Box sx={{ mt: ['auto'] }}>
            {variant != 'horizontalShadow' && (
              <PageCardBlogInfo
                blogAuthors={blogAuthors}
                minutesToRead={minutesToRead}
                variant={variant}
                localizedDate={localizedDate}
              />
            )}
          </Box>
        </Flex>
      </Card>
    </Box>
  );
};

const PageCategoryLink = ({ category }: { category: string }): ReactElement => {
  const href = useMemo(() => {
    const blogCategoryLookup = getBlogCategoriesDataLookup();
    const categorySlug = blogCategoryLookup[category]?.slug;

    if (!categorySlug) {
      return `/blog/`;
    }
    return `/blog/category/${categorySlug}/`;
  }, [category]);

  return (
    <Link href={href} sx={CARD_CATEGORY_LINK_STYLES}>
      <span sx={CARD_CATEGORY_STYLES}>{category}</span>
    </Link>
  );
};

const PageCardBlogInfo = ({
  blogAuthors,
  localizedDate,
  variant,
  minutesToRead,
}: {
  blogAuthors?: BlogAuthor[];
  localizedDate: string;
  variant: string;
  minutesToRead?: number;
}): ReactElement => {
  const { t } = useI18next();

  const isMobile = useResponsiveValue([true, false]);

  return (
    <p sx={CARD_INFO_STYLES}>
      {blogAuthors?.map((blogAuthor, i) => (
        <Fragment key={i}>
          <Link
            href={`/blog/author/${blogAuthor?.person?.slug || 'sprinklr-team'}`}
            sx={CARD_LINK_STYLES}
          >
            <span sx={{ fontWeight: 'semiBold' }}>
              {blogAuthor?.person?.authorName}
            </span>
          </Link>

          <span>{i !== blogAuthors.length - 1 ? ', ' : ''}</span>
        </Fragment>
      ))}
      {isMobile || variant === 'verticalShadow' ? (
        <br />
      ) : (
        <Fragment>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</Fragment>
      )}
      {localizedDate}
      {minutesToRead ? (
        <Fragment>
          <span aria-hidden="true">&nbsp;&nbsp;&bull;&nbsp;&nbsp;</span>
          {minutesToRead} {t(MINUTES_TO_READ)}
        </Fragment>
      ) : null}
    </p>
  );
};

export default BlogPostCard;
