/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import { graphql } from 'gatsby';
import ContentHubLayout from '../components/ContentHubLayout';
import { ContentHubBreadcrumbItems, ContentHubPostPageProps } from '../types';
import { useTranslation } from 'react-i18next';
import {
  getMinutesToReadByBody,
  getMinutesToReadReturnPost,
} from '@sprinklr/website/scripts/getMinutesToRead';
import { Container, HeadSchemaAdditions } from '@sprinklr/shared-lib';
import PostPageContentColumns from '@sprinklr/shared-lib/components/postPageContent/PostPageContentColumns';
import FormTemplate from '../../src/templates/formTemplate';
import { getSharedHeroLayoutSectionProps } from '../utils/getSharedHeroLayoutSectionProps';
import { useMemo } from 'react';
import { isWindowDefined } from '@sprinklr/shared-lib/utils/isWindowDefined';
import { getBreadcrumbSchema } from '../utils/getBreadcrumbSchema';
import { getBlogFullPath } from '../../blogSrc/utils/getBlogSlugs';
import {
  getBlogAuthorName,
  getAllBlogAuthors,
} from '../../blogSrc/utils/getBlogAuthorInfo';
import { getBlogSchema } from '../../blogSrc/utils/getBlogSchema';
import ContentHubPostHero from '../components/ContentHubPostHero';
import { SectionTitleStyles } from '../../src/templates/contentTitleTemplate/types';
import BlogCardfullDisplay from '../../blogSrc/components/BlogCardfullDisplay/BlogCardfullDisplay';
import {
  ContentHubPostDataAttributes,
  RELATED_ARTICLES_END_INDEX,
  RELATED_ARTICLES_START_INDEX,
} from '../constants';
import { removeLineBreaks } from '../utils';
import { getArticles } from '../../utils/getArticles';
import { getTranslationContextVariable } from '@sprinklr/shared-lib/utils/languageData';

export const contentHubPostPageQuery = graphql`
  query (
    $slug: String!
    $nodeLocale: String!
    $isHeaderV3Enabled: Boolean!
    $translationLocale: String!
    $language: String!
    $primaryBlogCategory: String!
  ) {
    ...HeaderFooterSocialQuery
    ...LocalesQuery
    ...ContentHubPostPageQueryFragment
    ...BlogRelatedPostQueryFragment
    ...SiteMetadataQuery
    requestDemoForm: allContentfulTemplateForm(
      filter: {
        uniqueFormUseCaseId: { eq: "CONTENT_HUB_REQUEST_DEMO" }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...ContentfulTemplateFormNode
      }
    }
    subscribeForm: allContentfulTemplateForm(
      filter: {
        uniqueFormUseCaseId: { eq: "CONTENT_HUB_SUBSCRIBE" }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...ContentfulTemplateFormNode
      }
    }
    heroMedia: contentfulMedia(
      uniqueMediaUseCaseId: { eq: "CONTENT_HUB_HERO_BG" }
      node_locale: { eq: $nodeLocale }
    ) {
      ... on ContentfulMedia {
        asset {
          file {
            url
          }
        }
      }
    }
  }
`;

const ContentHubPostPageTemplate: React.FC<ContentHubPostPageProps> = ({
  data,
  pageContext,
}) => {
  const { t } = useTranslation();
  const {
    headerV3,
    footerV3,
    allContentfulGlobalHeaderFooter,
    contentfulContentHubPostPage,
    heroMedia,
    site,
    relatedBlogPosts,
  } = data;
  const {
    contentHubPostBody,
    contentHubPostHeadline,
    contentHubPostSubheadline,
    contentHubPostCategory,
    metadata,
    node_locale,
    relatedProductCategories,
    relatedProductPageLinks,
    relatedTopics,
    slug,
    hideChat,
    sortingDate: publishDate,
    blogAuthors,
    personalization,
    requestDemoForm,
    subscribeForm,
  } = contentfulContentHubPostPage;

  const layoutProps = getSharedHeroLayoutSectionProps(
    contentHubPostHeadline,
    contentHubPostSubheadline,
  );
  const relatedBlogPostsWithMinutesToRead = relatedBlogPosts?.nodes?.map(post =>
    getMinutesToReadReturnPost(post),
  );

  const authorName = getBlogAuthorName(blogAuthors || []);

  const minutesToRead = useMemo(
    () => getMinutesToReadByBody(contentHubPostBody),
    [contentHubPostBody],
  );

  const breadcrumbSchema = useMemo(() => {
    const homePageUrl = isWindowDefined()
      ? `${window.location.origin}/`
      : 'https://www.sprinklr.com/';
    const cxmHomePageUrl = `${homePageUrl}cxm/`;

    const breadcrumbItems: ContentHubBreadcrumbItems = [
      {
        label: t('Home'),
        link: homePageUrl,
      },
      {
        label: t('CXM Guide'),
        link: cxmHomePageUrl,
      },
      {
        label: t(removeLineBreaks(contentHubPostHeadline)),
      },
    ];

    return getBreadcrumbSchema(breadcrumbItems);
  }, [contentHubPostHeadline]);

  const breadcrumbItems: ContentHubBreadcrumbItems = useMemo(() => {
    return [
      {
        label: t('Home'),
        link: '/',
      },
      {
        label: t('CXM Guide'),
        link: node_locale === 'en-US' ? '/cxm' : undefined,
      },
      {
        label: t(removeLineBreaks(contentHubPostHeadline)),
      },
    ];
  }, [contentHubPostHeadline, node_locale]);

  const blogAuthorNames = useMemo(
    () => getAllBlogAuthors(blogAuthors),
    [blogAuthors],
  );

  const articleSchema = useMemo(() => {
    return getBlogSchema({
      url: getBlogFullPath(`/${slug}/`),
      headline: contentHubPostHeadline,
      description: metadata?.seoDescription?.seoDescription,
      datePublished: new Date(publishDate).toISOString(),
      authorName,
    });
  }, [metadata, contentHubPostHeadline, slug, publishDate, authorName]);

  const resolvedRelatedArticles = useMemo(() => {
    const language = getTranslationContextVariable(node_locale);
    const filteredRelatedTopics =
      relatedTopics?.filter(topic =>
        topic.translations.languagesToRenderIn.includes(language),
      ) ?? [];

    return getArticles(
      [...filteredRelatedTopics, ...relatedBlogPostsWithMinutesToRead].slice(
        RELATED_ARTICLES_START_INDEX,
        RELATED_ARTICLES_END_INDEX,
      ),
      node_locale,
    );
  }, [relatedTopics, relatedBlogPosts, node_locale]);

  return (
    <ContentHubLayout
      context={pageContext}
      slug={slug}
      metadata={metadata}
      site={site}
      headerV3={headerV3}
      footerV3={footerV3}
      globalSkeletonData={allContentfulGlobalHeaderFooter?.edges[0]?.node}
      pageCategory={t(contentHubPostCategory)}
      nodeLocale={node_locale}
      hideChat={hideChat}
      breadcrumbSchema={breadcrumbSchema}
      breadcrumbItems={breadcrumbItems}
      dataAttributes={ContentHubPostDataAttributes}
      personalization={personalization}
    >
      <HeadSchemaAdditions schema={articleSchema} />
      <ContentHubPostHero
        sectionTitle={layoutProps.sectionTitle}
        sectionTitleStyles={
          layoutProps.sectionTitleStyles as SectionTitleStyles
        }
        backgroundMedia={heroMedia}
        minutesToRead={minutesToRead}
        publishDate={publishDate}
        authorNames={blogAuthorNames}
      />
      <Container
        sx={{ paddingTop: [null, '50px', null, '80px'] }}
        className={'scn-cxm-post-page-content'}
      >
        <PostPageContentColumns
          postTitle={contentHubPostHeadline}
          postBody={contentHubPostBody}
          featuredProductCategories={relatedProductCategories}
          featuredProductPageLinks={relatedProductPageLinks}
          nodeLocale={node_locale}
          slug={slug}
          demoForm={requestDemoForm}
          subscribeForm={subscribeForm}
          socialSectionTitle={t('Share this Topic')}
          productSectionTitle={t('related products')}
          relatedTopics={resolvedRelatedArticles}
          pageType={'CONTENT_HUB'}
          styleVariant={'REDUCED_HEADLINE'}
        />
        <FormTemplate
          {...requestDemoForm}
          formTemplateSx={{
            px: ['0', '0', '0', '0'],
            py: ['0', '0', '0', '50px'],
          }}
          externalValidId={'idContentHubPostPage'}
        />
        {relatedBlogPostsWithMinutesToRead?.length > 0 && (
          <Box
            sx={{ marginTop: ['80px', null, null, '100px'] }}
            className={'scn-cxm-related-blog-posts'}
          >
            <BlogCardfullDisplay
              posts={relatedBlogPostsWithMinutesToRead}
              title={t('Related Content')}
              titleMargin="small"
            />
          </Box>
        )}
      </Container>
    </ContentHubLayout>
  );
};

export default ContentHubPostPageTemplate;
