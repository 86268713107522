/** @jsx jsx */
import {
  getCorrectedEncodeUriComponent,
  SocialLink,
} from '@sprinklr/shared-lib';
import { Flex, Box, Themed, jsx } from 'theme-ui';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { PostPageSocialShareProps } from './types';
import { isWindowDefined } from '@sprinklr/shared-lib/utils/isWindowDefined';

const PostPageSocialShare: React.FC<PostPageSocialShareProps> = ({
  name,
  socialSectionTitle,
  postPageSocialShareSx,
}) => {
  const uriEncodedName = getCorrectedEncodeUriComponent(name);
  const location = useLocation();
  const { t } = useTranslation();
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (isWindowDefined()) {
      setUrl(location.href);
    }
  }, [location]);

  return (
    <Box
      sx={{
        maxWidth: ['300px', null, null, '325px'],
        margin: '0 auto 30px',
        ...postPageSocialShareSx,
      }}
    >
      <Themed.h6
        as="p"
        sx={{
          color: '#A0A0A8',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          fontSize: [1, 2, 2, 2],
          letterSpacing: '1.2px',
          textAlign: ['center', null, null, 'left'],
        }}
      >
        {socialSectionTitle}
      </Themed.h6>
      <Flex sx={{ justifyContent: 'space-between' }}>
        <SocialLink
          key={'facebook'}
          socialLink={{
            channelType: 'FACEBOOK',
            url: `https://www.facebook.com/sharer.php?u=${url}`,
          }}
          variant={'GREY2COLOR'}
          fillColor={'#383F3B'}
          shouldOpenNewWindow={true}
        />
        <SocialLink
          key={'twitter'}
          socialLink={{
            channelType: 'TWITTER',
            url: `https://twitter.com/intent/tweet?text=${uriEncodedName}&url=${url}`,
          }}
          variant={'GREY2COLOR'}
          fillColor={'#383F3B'}
          shouldOpenNewWindow={true}
        />
        <SocialLink
          key={'linkedin'}
          socialLink={{
            channelType: 'LINKEDIN',
            url: `https://www.linkedin.com/shareArticle?url=${url}&title=${uriEncodedName}`,
          }}
          variant={'GREY2COLOR'}
          fillColor={'#383F3B'}
          shouldOpenNewWindow={true}
        />
        <SocialLink
          key={'mail'}
          socialLink={{
            channelType: 'MAIL',
            url: `mailto:?subject=Sprinklr.com: ${name}&body=${url}`,
          }}
          variant={'GREY2COLOR'}
          fillColor={'#383F3B'}
        />
      </Flex>
    </Box>
  );
};

export default PostPageSocialShare;
