const DEFAULT_AUTHOR_NAME = 'Sprinklr Team';

type BlogAuthor = {
  person: {
    authorName?: string;
  };
};

export const getBlogAuthorName = (blogAuthors: BlogAuthor[]): string => {
  const author = blogAuthors.length > 0 ? blogAuthors[0].person : null;
  if (!author) return DEFAULT_AUTHOR_NAME;
  return author.authorName || DEFAULT_AUTHOR_NAME;
};

export const getAllBlogAuthors = (blogAuthors: BlogAuthor[]): string[] => {
  if (!blogAuthors || blogAuthors.length === 0) return [DEFAULT_AUTHOR_NAME];
  let useDefaultAuthor = true;
  const authorNames = blogAuthors
    ?.map(blogAuthor => {
      const author = blogAuthor?.person?.authorName;
      return author ?? DEFAULT_AUTHOR_NAME;
    })
    .filter(author => {
      if (author === DEFAULT_AUTHOR_NAME && !useDefaultAuthor) return false;
      if (author === DEFAULT_AUTHOR_NAME) useDefaultAuthor = false;
      return true;
    });
  return authorNames;
};
