const personalizationProductCategories: string[] = [
  'Sprinklr Service',
  'Sprinklr Social',
  'Sprinklr Marketing',
  'Sprinklr Insights',
];

export const checkProductCategoryForPersonalization = (
  productLabel: string,
): boolean => {
  return personalizationProductCategories.includes(productLabel?.trim());
};
