/** @jsx jsx */
import { Grid, Box, jsx } from 'theme-ui';
import { PostPageStickyColumnProps } from './types';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { CallToAction, StickyElement } from '@sprinklr/shared-lib';
import PostPageSocialShare from './PostPageSocialShare';
import PostPageFeaturedProducts from './PostPageFeaturedProducts';
import PostPageSectionDivider from './PostPageSectionDivider';
import RelatedTopics from './RelatedTopics';

const PostPageStickyColumn: React.FC<PostPageStickyColumnProps> = props => {
  const {
    postTitle,
    featuredProductCategories,
    featuredProductPageLinks,
    demoForm,
    subscribeForm,
    productSectionTitle,
    relatedTopics,
    socialSectionTitle,
    nodeLocale,
    pageType,
  } = props;

  const { t, language } = useI18next();
  const showSubscribeButton = !(
    pageType === 'CONTENT_HUB' && language === 'en'
  );

  return (
    <StickyElement
      stickyElementSx={{
        top: '150px',
        position: 'sticky',
      }}
    >
      <Grid
        sx={{
          gridTemplateColumns: [
            '1fr',
            featuredProductCategories || featuredProductPageLinks
              ? '1fr 1fr'
              : '1fr',
            null,
            '1fr',
          ],
          gridColumnGap: '64px',
          gridRowGap: '48px',
          py: ['40px', '50px', null, 0],
          position: 'relative',
        }}
      >
        <PostPageSectionDivider position="TOP" />
        <Box>
          <PostPageSocialShare
            name={postTitle}
            socialSectionTitle={socialSectionTitle}
          />
          <Grid
            sx={{
              gridTemplateColumns: [
                '1fr',
                featuredProductCategories ? '1fr' : '1fr 1fr',
                null,
                '1fr',
              ],
              gridGap: '12px',
            }}
          >
            <CallToAction
              type="BUTTON"
              variant="primary"
              text={t('request demo')}
              action="OPEN_MODAL"
              genModalContent={demoForm}
            />
            {showSubscribeButton && (
              <CallToAction
                type="BUTTON"
                variant="secondary"
                text={t('subscribe')}
                action="OPEN_MODAL"
                genModalContent={subscribeForm}
                modalShouldFitToContent={true}
              />
            )}
          </Grid>
        </Box>
        {(featuredProductCategories || featuredProductPageLinks) && (
          <PostPageFeaturedProducts
            featuredProductCategories={featuredProductCategories}
            featuredProductPageLinks={featuredProductPageLinks}
            productSectionTitle={productSectionTitle}
            nodeLocale={nodeLocale}
            pageType={pageType}
          />
        )}
        <PostPageSectionDivider position="BOTTOM" />
      </Grid>

      {relatedTopics?.length > 0 ? (
        <RelatedTopics relatedTopics={relatedTopics} />
      ) : null}
    </StickyElement>
  );
};

export default PostPageStickyColumn;
