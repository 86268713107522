/** @jsx jsx */
import React, { Fragment } from 'react';
import { CardGrid } from '@sprinklr/shared-lib';
import { jsx, Box, Themed } from 'theme-ui';
import BlogCard from './BlogPostCard';

type props = {
  title?: string;
  variant?: 'horizontalWide' | 'vertical';
  titleMargin?: 'small' | 'medium';
  posts: any[];
};

const BlogCardfullDisplay: React.FC<props> = ({
  title,
  posts,
  variant = 'vertical',
  titleMargin = 'medium',
}) => {
  const titleMarginOptions = {
    medium: [4, null, 5],
    small: [3, 3, 4],
  };

  return (
    <Fragment>
      {title && (
        <Box sx={{ mb: titleMarginOptions[titleMargin] }}>
          <Themed.h3 as="p" sx={{ marginBottom: [0] }}>
            {title}
          </Themed.h3>
        </Box>
      )}

      <CardGrid
        gridSx={{ rowGap: ['40px', '48px', '48px', '54px'] }}
        cardVariant={variant}
      >
        {posts?.map((postData, i) => {
          return (
            <BlogCard
              key={`${i}+${postData?.slug}`}
              slug={postData?.slug}
              cardTitle={postData?.blogPostTitle}
              cardDescription={postData?.blogCardDescription}
              cardImage={postData?.blogPostImage}
              publishDate={postData?.publishDate}
              category={postData?.primaryBlogCategory}
              blogAuthors={postData?.blogAuthors}
              minutesToRead={postData?.minutesToRead}
              node_locale={postData?.node_locale}
              variant={
                variant === 'horizontalWide'
                  ? 'horizontalClean'
                  : 'verticalShadow'
              }
            />
          );
        })}
      </CardGrid>
    </Fragment>
  );
};
export default BlogCardfullDisplay;
