/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import { PostPageSectionDividerProps } from './types';

const PostPageSectionDivider: React.FC<PostPageSectionDividerProps> = ({ position }) => {
  return (
    <Box
      sx={{
        // Hacky way to fake border on a section within a container
        position: 'absolute',
        height: '1px',
        width: '100vw',
        background: 'lightGrey',
        display: ['block', null, null, 'none'],
        left: position ? ['-22px', '-80px', null, 0] : ['0px'],
        top: position === 'TOP' && 0,
        bottom: position === 'BOTTOM' && 0,
      }}
    />
  );
};

export default PostPageSectionDivider;
