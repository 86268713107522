/** @jsx jsx */
import { useMemo } from 'react';
import { Grid, Box, jsx, Flex } from 'theme-ui';
import { PostPageProductFeatureProps } from './types';
import { checkForAndAddTrailingSlash } from '@sprinklr/shared-lib/utils/getPostPageSlugs';
import { Link } from '@sprinklr/shared-lib';
import { localizePath } from '@sprinklr/shared-lib/utils/localizePath';
import { getPageSlug } from '@sprinklr/shared-lib/utils/featurePage';
import { getTranslationContextVariable } from '../../utils/languageData';
import { checkProductCategoryForPersonalization } from '@sprinklr/website/contentHubSrc/utils/productCategories';
import { DataAttributes } from '../../@types/entities';
import {
  DEFAULT_PRODUCT_TAG,
  DEFAULT_PRODUCT_LABEL,
  PRODUCT_REGEX,
} from './constants';

const PostPageProductFeature: React.FC<PostPageProductFeatureProps> = ({
  Icon,
  productLabel,
  productSlug,
  productList,
  nodeLocale,
  pageType,
}) => {
  const filteredProductList = useMemo(
    () => productList.filter(page => page?.pageReference),
    [productList],
  );

  const localizedProductSlug = localizePath(nodeLocale, productSlug);

  const productTagDataAttribute: DataAttributes = useMemo(() => {
    if (
      pageType === 'CONTENT_HUB' &&
      checkProductCategoryForPersonalization(productLabel)
    ) {
      const productTagContent =
        productLabel?.trim()?.toUpperCase()?.replace(PRODUCT_REGEX, '-') ??
        DEFAULT_PRODUCT_LABEL;
      const productDataAttributes: DataAttributes = {
        dataElementType: DEFAULT_PRODUCT_TAG,
        dataElementId: `${DEFAULT_PRODUCT_TAG}-${productTagContent}`,
      };
      return productDataAttributes;
    }
    return null;
  }, [pageType, productLabel]);

  return (
    <Grid
      sx={{ gridTemplateColumns: 'auto 1fr', gridRowGap: '10px', mt: '24px' }}
    >
      <Flex
        sx={{
          position: 'relative',
          top: '2px',
          width: '20px',
          height: '20px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Icon
          sx={{ width: '20px', height: '20px', path: { fill: '#000000' } }}
          aria-hidden="true"
        />
      </Flex>
      <Box>
        <Link
          href={localizedProductSlug}
          sx={{
            color: 'text',
            margin: 0,
            padding: 0,
            fontWeight: 'bold',
            fontSize: ['16px', '16px', '16px', '16px'],
            lineHeight: ['20px', '20px', '20px', '20px'],
            '&:hover': {
              color: 'text',
              borderColor: 'transparent',
              textDecoration: 'underline',
            },
          }}
          dataAttributes={productTagDataAttribute}
        >
          {productLabel}
        </Link>
      </Box>
      {filteredProductList && filteredProductList.length > 0
        ? filteredProductList.map((page, i) => {
            const {
              categorySlug,
              navSlug,
              productCategory,
              metadata: { internalLinkLabel },
              translations,
            } = page?.pageReference;
            const featurePageSlug = getPageSlug({
              slug: checkForAndAddTrailingSlash(navSlug),
              categorySlug,
              productCategory,
            });
            const localizedPath = localizePath(nodeLocale, featurePageSlug);

            if (
              !!translations?.languagesToRenderIn &&
              !translations.languagesToRenderIn.includes(
                getTranslationContextVariable(nodeLocale),
              )
            ) {
              return null;
            }

            return (
              <Box sx={{ gridColumn: '2', lineHeight: '20px' }} key={i}>
                <Link
                  href={localizedPath}
                  sx={{
                    margin: 0,
                    fontWeight: 'semiBold',
                    fontSize: ['14px', '14px', '14px', '16px'],
                    ':hover': {
                      borderBottomColor: 'transparent',
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {internalLinkLabel}
                </Link>
              </Box>
            );
          })
        : null}
    </Grid>
  );
};

export default PostPageProductFeature;
