import { Image } from '@sprinklr/shared-lib/@types/entities';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import React, { ReactElement, useMemo } from 'react';
import { Flex, ThemeUIStyleObject } from 'theme-ui';
import ContentTitle from '../../src/templates/contentTitleTemplate';
import { SectionTitleStyles } from '../../src/templates/contentTitleTemplate/types';
import ContentHubPostInfo from './ContentHubPostInfo';

interface ContentHubPostHeroProps {
  sectionTitle: {
    headlineText: string;
    subHeadlineText: string;
    icon?: {
      asset: Image;
    };
  };
  sectionTitleStyles?: SectionTitleStyles;
  minutesToRead: number;
  publishDate: string;
  authorNames: string[];
  backgroundMedia: {
    asset: Image;
  };
}

function getContainerStyles(
  backgroundMedia: { asset: Image },
  pt: [number, number],
  pb: [number, number],
): ThemeUIStyleObject {
  return {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: [`url(${backgroundMedia.asset.file.url})`],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    pt,
    pb,
  };
}

const ContentHubPostHero = ({
  sectionTitle,
  sectionTitleStyles,
  minutesToRead,
  publishDate,
  authorNames,
  backgroundMedia,
}: ContentHubPostHeroProps): ReactElement => {
  const [pt, pb] = useContainerPadding({ top: 'MEDIUM', bottom: 'MEDIUM' });

  const containerSx = useMemo<ThemeUIStyleObject>(
    () => getContainerStyles(backgroundMedia, pt, pb),
    [backgroundMedia, pt, pb],
  );

  return (
    <Flex sx={containerSx} className="scn-cxm-post-page-hero">
      <ContentTitle
        headlineText={sectionTitle.headlineText}
        subHeadlineText={sectionTitle.subHeadlineText}
        titleStyles={sectionTitleStyles}
      />
      <ContentHubPostInfo
        minutesToRead={minutesToRead}
        publishDate={publishDate}
        authorNames={authorNames}
      />
    </Flex>
  );
};

export default ContentHubPostHero;
