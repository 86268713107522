import { isWindowDefined } from '@sprinklr/shared-lib/utils/isWindowDefined';
import { localizePath } from '@sprinklr/shared-lib/utils/localizePath';
import { getBlogCategoriesDataLookup } from '../utils/blogCategoriesLookup';

//when using these utils, pass in a slug with no trailing slash (basically the slug out of contentful)
export const getBlogAuthorPageSlug = (slug, nodeLocale) =>
  localizePath(nodeLocale, `/blog/author/${slug}/`);
export const getBlogCategoryPageSlug = (category, nodeLocale) =>
  localizePath(
    nodeLocale,
    `blog/category/${getBlogCategoriesDataLookup()[category]?.slug}/`,
  );
export const checkForAndRemoveTrailingSlash = slug =>
  slug.endsWith('/') ? slug.slice(0, -1) : slug;
export const checkForAndAddTrailingSlash = slug =>
  slug.endsWith('/') ? slug : `${slug}/`;
export const checkForAndAddLeadingSlash = slug =>
  slug.startsWith('/') ? slug : `/${slug}`;
const nodeLocaleRegex = new RegExp(
  /^\/?(en|de|fr|es|jp|de|pt-br|gb|ko|sg|au|kr|ar)\//,
  '',
);
export const removeNodeLocaleFromSlug = slug =>
  slug.replace(nodeLocaleRegex, '/');

export const getPaginationSlugsAndNumber = (
  slug,
  numberOfPages,
  paginatedPageNumber,
  anchorTagId,
) => {
  const slugWithoutPageNumber =
    checkForAndRemoveTrailingSlash(slug)?.split('/page/')[0];
  const slugWithoutNodeLocaleAndPageNumber = removeNodeLocaleFromSlug(
    slugWithoutPageNumber,
  );

  let backSlug = '';
  let forwardSlug = '';

  if (paginatedPageNumber > 1)
    backSlug =
      paginatedPageNumber === 2
        ? checkForAndAddLeadingSlash(
            `${slugWithoutNodeLocaleAndPageNumber}/#${anchorTagId}`,
          )
        : checkForAndAddLeadingSlash(
            `${slugWithoutNodeLocaleAndPageNumber}/page/${
              paginatedPageNumber - 1
            }/#${anchorTagId}`,
          );
  if (paginatedPageNumber < numberOfPages)
    forwardSlug = checkForAndAddLeadingSlash(
      `${slugWithoutNodeLocaleAndPageNumber}/page/${
        paginatedPageNumber + 1
      }/#${anchorTagId}`,
    );

  return { backSlug, forwardSlug };
};

export const getBlogFullPath = slug => {
  if (!slug) return '';

  const host = isWindowDefined() ? window.location.host : 'www.sprinklr.com';
  const protocol = isWindowDefined() ? window.location.protocol : 'https';
  const url = `${protocol}//${host}`;
  return `${url}${slug}`;
};
