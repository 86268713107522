import { ThemeUIStyleObject } from 'theme-ui';

export const CARD_LINK_STYLES: ThemeUIStyleObject = {
  color: 'text',
  border: 'none',
  '&:hover': {
    textDecoration: 'none',
    color: 'primary',
  },
};

export const CARD_CATEGORY_STYLES: ThemeUIStyleObject = {
  p: ['4px 8px'],
  background: '#F1F1F1',
  color: '#646470',
  borderRadius: '4px',
  fontSize: 0,
  lineHeight: '15px',
  display: 'inline-block',
  fontWeight: 'semiBold',
  mb: ['12px', '16px'],
  '&:hover': {
    color: 'primary',
  },
};

export const CARD_INFO_STYLES: ThemeUIStyleObject = {
  fontSize: ['14px', '16px'],
  fontWeight: 'regular',
  mb: '6px',
  mt: '4px',
};

export const CARD_CATEGORY_LINK_STYLES: ThemeUIStyleObject = {
  ...CARD_LINK_STYLES,
  width: 'fit-content',
};

export const IMAGE_LINK_STYLES: ThemeUIStyleObject = { padding: 0 };
