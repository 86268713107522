export const getBlogCategories = () => [
  'Unified-CXM',
  'Unified-CXM Experience',
  'Platform & Technology',
  'Customer Service',
  'Marketing & Advertising',
  'Research & Insights',
  'Social Media Management',
  'Customer Stories',
  'Announcements',
  'Culture & Talent',
  'AI',
];

const personalizationBlogCategories = [
  'Unified-CXM',
  'Platform & Technology',
  'Customer Service',
  'Marketing & Advertising',
  'Customer Stories',
  'Social Media Management',
  'Research & Insights',
];

export const checkBlogCategoryForPersonalization = blogCategory => {
  return personalizationBlogCategories.includes(blogCategory?.trim());
};

export const getBlogCategoriesDataLookup = () => ({
  'Unified-CXM': {
    slug: 'unified-cxm',
    subHeader: 'Make customers happier with a unified customer experience.',
    seoTitle: 'Latest Unified-CXM Insights',
    seoDescription:
      'Read the latest Unified-CXM blogs from Sprinklr for insights, strategy, and expert opinions on creating a unified customer experience.',
  },
  'Unified-CXM Experience': {
    slug: 'unified-cxm-experience',
    subHeader: 'The Unified-CXM Experience Podcast by Sprinklr CXO Grad Conn.',
    seoTitle: 'Unified-CXM Experience',
    seoDescription:
      'The Unified-CXM Experience Podcast by Sprinklr CXO Grad Conn',
  },
  'Platform & Technology': {
    slug: 'platform-technology',
    subHeader:
      'Reach, engage, and serve customers with powerful AI-powered platform technology.',
    seoTitle: 'Latest Platform & Technology Blogs',
    seoDescription:
      'Read the latest Platform and Technology posts from the Sprinklr blog to learn more about our powerful AI-powered technology.',
  },
  'Customer Service': {
    slug: 'customer-service',
    subHeader: 'Create a digital-first, proactive customer service experience.',
    seoTitle: 'Latest Customer Service & Support Blogs',
    seoDescription:
      'Read the latest customer service blog posts from the Sprinklr blog to learn how to create a digital-first, proactive customer service experience.',
  },
  'Marketing & Advertising': {
    slug: 'marketing-advertising',
    subHeader: 'Boost your brand and prove the value of your digital spend.',
    seoTitle: 'Latest Marketing & Advertising Blogs',
    seoDescription:
      'Read the latest marketing and advertising blog posts from the Sprinklr blog to boost your brand and prove the value of your digital spend.',
  },
  'Research & Insights': {
    slug: 'research-insights',
    subHeader: 'Turn customer data into powerful AI-driven insights.',
    seoTitle: 'Latest Research & Insights Blogs',
    seoDescription:
      'Read the latest research and insights blog posts from the Sprinklr blog to turn customer data into powerful AI-driven insights.',
  },
  'Social Media Management': {
    slug: 'social-media-management',
    subHeader:
      'Drive revenue and engage directly with your customers on digital channels.',
    seoTitle: 'Latest Social Media Management Posts',
    seoDescription:
      'Read the latest social media management blog posts from the Sprinklr blog to drive revenue and engage directly with your customers on digital channels.',
  },
  'Customer Stories': {
    slug: 'customer-stories',
    subHeader:
      'Learn how leading brands unifying the customer experience with Sprinklr.',
    seoTitle: 'Latest Customer Stories Posts',
    seoDescription:
      'Read the latest customer stories from the Sprinklr blog and learn how leading brands unifying the customer experience with Sprinklr.',
  },
  Announcements: {
    slug: 'announcements',
    subHeader:
      'Get the latest news, press releases, and announcements from Sprinklr.',
    seoTitle: 'Sprinklr Announcements',
    seoDescription:
      'Get the latest news, press releases, and announcements from Sprinklr.',
  },
  'Culture & Talent': {
    slug: 'culture-talent',
    subHeader:
      'See how The Sprinklr Way paves the way for working, living, and being.',
    seoTitle: 'Latest Culture & Talent Posts',
    seoDescription:
      'Read the latest culture and talent posts from the Sprinklr blog and see how The Sprinklr Way paves the way for working, living, and being.',
  },
  AI: {
    slug: 'ai',
    subHeader:
      'Drive efficiency, innovation and growth with AI-powered insights, marketing, sales and customer service.',
    seoTitle: 'Latest insights, news and articles on AI',
    seoDescription:
      'Read the latest artificial intelligence articles from the Sprinklr blog and learn about expert insights, best practices and tools to stay ahead of the AI curve.',
  },
});
