import { checkForAndAddTrailingSlash } from '@sprinklr/shared-lib/utils/getPostPageSlugs';
import { localizePath } from '@sprinklr/shared-lib/utils/localizePath';

type ContentfulContentHubPostPage = {
  __typename: 'ContentfulContentHubPostPage';
  slug: string;
  contentHubPostHeadline: string;
};

type BlogPost = {
  __typename: 'ContentfulBlogPostPage';
  slug: string;
  blogPostTitle: string;
};

type Article = ContentfulContentHubPostPage | BlogPost;

type TransformedArticle = {
  slug: string;
  title: string;
};

const BLOG_PREFIX = '/blog/';
const CXM_PREFIX = '/cxm/';

export const getArticles = (
  articles: Article[],
  nodeLocale: string,
): TransformedArticle[] => {
  return articles.map(article => {
    const slug = checkForAndAddTrailingSlash(article?.slug);
    if (article.__typename === 'ContentfulBlogPostPage') {
      return {
        slug: localizePath(nodeLocale, BLOG_PREFIX + slug)!,
        title: article.blogPostTitle,
      };
    }

    return {
      slug: localizePath(nodeLocale, CXM_PREFIX + slug)!,
      title: article.contentHubPostHeadline,
    };
  });
};
