/** @jsx jsx */
import { Flex, jsx } from 'theme-ui';
import { PostPageCategoryCardsProps } from './types';
import PostCategoryEyebrow from './PostCategoryEyeBrow';

const PostPageCategoryCards: React.FC<PostPageCategoryCardsProps> = props => {
  const { primaryCategory, secondaryCategory, nodeLocale, pageType } = props;

  return (
    <Flex sx={{ flexWrap: 'wrap', mt: '48px' }}>
      <PostCategoryEyebrow category={primaryCategory} nodeLocale={nodeLocale} pageType={pageType} />
      {secondaryCategory &&
        secondaryCategory.map((category, i) => <PostCategoryEyebrow key={i} category={category} nodeLocale={nodeLocale} pageType={pageType} />)}
    </Flex>
  );
};

export default PostPageCategoryCards;
