/** @jsx jsx */
import { Box, Themed, jsx } from 'theme-ui';
import { PostPageFeaturedProductsProps } from './types';
import { getFeaturedProductsData } from '@sprinklr/shared-lib/utils/getFeaturedProductsData';
import PostPageProductFeature from './PostPageProductFeature';

const PostPageFeaturedProducts: React.FC<
  PostPageFeaturedProductsProps
> = props => {
  const {
    featuredProductCategories,
    featuredProductPageLinks,
    productSectionTitle,
    nodeLocale,
    pageType,
  } = props;

  const showRelatedProduct = product =>
    featuredProductCategories?.includes(product.productLabel) ||
    !!product.productList?.length;
  const allFeaturedProductsData = getFeaturedProductsData();

  featuredProductPageLinks?.forEach(page =>
    allFeaturedProductsData[page.relatedProduct]?.productList.push(page),
  );
  const featuredProducts = Object.values(allFeaturedProductsData);

  return (
    <Box>
      <Themed.h6
        as="p"
        sx={{
          color: '#A0A0A8',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          fontSize: [1, 2, 2, 2],
          letterSpacing: '1.2px',
          textAlign: ['center', null, null, 'left'],
        }}
      >
        {productSectionTitle}
      </Themed.h6>
      {featuredProducts.map(featuredProduct => {
        if (showRelatedProduct(featuredProduct))
          return (
            <PostPageProductFeature
              {...featuredProduct}
              nodeLocale={nodeLocale}
              pageType={pageType}
            />
          );
      })}
    </Box>
  );
};

export default PostPageFeaturedProducts;
