import { minutesToRead } from '@sprinklr/shared-lib/utils';

//if criteria of how posts are generated for an individual page changes, this will also have to be updated.
export const getMinutesToReadBySlug = config => {
  const { posts, pageNodeLocale, pagePrimaryBlogCategory, pageAuthorName } =
    config;
  const filteredPosts = JSON.parse(JSON.stringify(posts)).reduce(
    (filteredPosts, post) => {
      const { nodeLocale, primaryBlogCategory, blogAuthors } = post;
      if (
        isEqualOrNotImportant(pageNodeLocale, nodeLocale)
          ? isEqualOrNotImportant(pagePrimaryBlogCategory, primaryBlogCategory)
            ? !!pageAuthorName
              ? blogAuthors?.some(
                  ({ authorName }) => authorName === pageAuthorName,
                )
              : true
            : false
          : false
      ) {
        filteredPosts.push(post);
      }
      return filteredPosts;
    },
    [],
  );

  if (filteredPosts?.length > 1) {
    filteredPosts?.sort(
      (a, b) =>
        new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime(),
    );
  }

  //returns object of Ids mapped to integer of minutes to read
  return filteredPosts?.reduce((minutesToReadBySlug, post) => {
    const { slug, blogPostBody } = post;
    minutesToReadBySlug.push({
      slug,
      minutesToRead: minutesToRead(blogPostBody?.raw, 'richTextJson'),
    });
    return minutesToReadBySlug;
  }, []);
};

const isEqualOrNotImportant = (pageData, postData) =>
  !!pageData ? pageData === postData : true;

export const getMinutesToReadLookup = ({
  minutesToReadBySlug,
  cardsToSkip,
  cardsPerPage,
}) => {
  if (!minutesToReadBySlug?.length) return {};

  const copyMinBySlug = JSON.parse(JSON.stringify(minutesToReadBySlug));
  const postsWithSkippedPostsRemoved = cardsToSkip
    ? copyMinBySlug?.slice(cardsToSkip)
    : copyMinBySlug;

  return postsWithSkippedPostsRemoved?.reduce(
    (minutesToReadLookup, { slug, minutesToRead }, index) => {
      if (index < cardsPerPage) {
        minutesToReadLookup[slug] = minutesToRead;
      }
      return minutesToReadLookup;
    },
    {},
  );
};

export const getMinutesToReadReturnPost = post => {
  const { blogPostBody } = post;
  post.minutesToRead = minutesToRead(blogPostBody.raw, 'richTextJson');
  return post;
};

export const getMinutesToReadByBody = blogPostBody =>
  minutesToRead(blogPostBody.raw, 'richTextJson');
