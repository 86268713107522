/** @jsx jsx */
import { Box, jsx, ThemeUIStyleObject } from 'theme-ui';
import React, { useMemo, ReactElement } from 'react';
import {
  getBlogCategoriesDataLookup,
  checkBlogCategoryForPersonalization,
} from '@sprinklr/website/blogSrc/utils/blogCategoriesLookup';
import { Link } from '../link';
import { DataAttributes } from '../../@types/entities';
import { DEFAULT_BLOG_TAG, DEFAULT_BLOG_LABEL, BLOG_REGEX } from './constants';

type PostCategoryEyeBrowsProps = {
  category: string;
  postCategoryEyeBrowSx?: ThemeUIStyleObject;
  pageType?: 'BLOG' | 'CONTENT_HUB';
};

const DefaultCategoryEyeBrow: React.FC<PostCategoryEyeBrowsProps> = ({
  category,
  postCategoryEyeBrowSx,
}) => {
  const blogTagDataAttributes: DataAttributes = useMemo(() => {
    if (checkBlogCategoryForPersonalization(category)) {
      const blogTagContent =
        category?.trim()?.toUpperCase()?.replace(BLOG_REGEX, '-') ??
        DEFAULT_BLOG_LABEL;
      const blogDataAttributes: DataAttributes = {
        dataElementType: DEFAULT_BLOG_TAG,
        dataElementId: `${DEFAULT_BLOG_TAG}-${blogTagContent}`,
      };
      return blogDataAttributes;
    }
    return null;
  }, [category]);

  return (
    <Box
      sx={{
        display: 'inline-block',
        backgroundColor: 'muted',
        color: 'darkGrey',
        padding: '4px 8px',
        borderRadius: '4px',
        fontWeight: 'bold',
        fontSize: '12px',
        mb: ['12px', 3],
        mr: ['12px', 3],
        ...postCategoryEyeBrowSx,
      }}
      data-element-id={blogTagDataAttributes?.dataElementId}
      data-element-type={blogTagDataAttributes?.dataElementType}
    >
      {category}
    </Box>
  );
};

const BlogCategoryEyeBrow = ({
  category,
  postCategoryEyeBrowSx,
}: PostCategoryEyeBrowsProps): ReactElement => {
  const link = useMemo(() => {
    const blogCategoryLookup = getBlogCategoriesDataLookup();
    const categorySlug = blogCategoryLookup[category]?.slug;

    if (!categorySlug) {
      return null;
    }
    return `/blog/category/${categorySlug}/`;
  }, [category]);

  if (link === null) {
    return (
      <DefaultCategoryEyeBrow
        category={category}
        postCategoryEyeBrowSx={postCategoryEyeBrowSx}
      />
    );
  }

  return (
    <Link href={link}>
      <DefaultCategoryEyeBrow
        category={category}
        postCategoryEyeBrowSx={postCategoryEyeBrowSx}
      />
    </Link>
  );
};

const PostCategoryEyeBrow = ({
  pageType,
  ...rest
}: PostCategoryEyeBrowsProps): ReactElement => {
  switch (pageType) {
    case 'BLOG': {
      return <BlogCategoryEyeBrow {...rest} />;
    }
    default:
      return <DefaultCategoryEyeBrow {...rest} />;
  }
};

export default PostCategoryEyeBrow;
