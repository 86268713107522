import React, { Fragment, ReactElement } from 'react';
import { Box, Flex, ThemeUICSSObject } from 'theme-ui';

const POST_INFO_CONTAINER_STYLES: ThemeUICSSObject = {
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  paddingX: '10px',
};

const AUTHOR_NAME_STYLES: ThemeUICSSObject = {
  display: 'flex',
};

const ContentHubPostInfo = ({
  minutesToRead,
  publishDate,
  authorNames,
}: {
  minutesToRead: number;
  publishDate: string;
  authorNames: string[];
}): ReactElement => {
  return (
    <Flex sx={POST_INFO_CONTAINER_STYLES}>
      <Box sx={AUTHOR_NAME_STYLES}>
        {authorNames?.map((authorName, index) => (
          <Fragment>
            <span key={index}>{authorName}</span>
            {index < authorNames.length - 1 ? <Separator /> : null}
          </Fragment>
        ))}
      </Box>
      <Flex>
        <Box>
          <Separator />
          <span data-published-date={publishDate}>{publishDate}</span>
        </Box>
        <Box>
          <Separator />
          <span data-minutes-to-read={minutesToRead}>
            {minutesToRead} min read
          </span>
        </Box>
      </Flex>
    </Flex>
  );
};

const Separator = () => (
  <Box aria-hidden="true" as="span">
    &nbsp;&nbsp;&bull;&nbsp;&nbsp;
  </Box>
);

export default ContentHubPostInfo;
